export const Sites = [
    "Old Farm",
    "QuailBrook",
    "UHS",
    "Fairmeadows",
    "Country Side",
    "Oak Meadows",
    "Cross Pointe",
    "525",
    "Riverton Plaza",
    "13th St.",
    "Heather Ridge",
    "City Works",
    "Onyx",
    "JMH",
    "HVM",
    "Crescent Office Park",
    "River Ridge",
    "Jordan Winds",
    "Williamsburg",
    "Nike Dr.",
    "Ridge Top Plaza (Markham Investments)",
    "Ridge Top Plaza (Silcox LLC)",
    "Bob Wager",
    "Rushton",
    "L&R Properties",
    "C&L Ahlquist Investments",
    "Munter",
    "L&B Global Ventures",
    "LTC Properties",
    "Sagar",
    "FWT",
    "Garner",
    "JCU Midvale",
    "JCU Sandy",
    "JCU West Jordan",
    "JCU New Bingham",
    "JCU Riverton",
    "JCU Herriman",
    "JCU Draper",
    "JCU Admin",
    "Dashiell",
    "Rallysport",
    "Option 43",
];
