import React, { useState, useReducer, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from 'html2canvas';
import emailjs from 'emailjs-com';
import { Sites } from './Sites';

let initialState = {
    wr: "",
    selectOption: "",
    requestBy: "",
    requestDate: "",
    other: "",
    emergency: "",   workRequest: "",
    partsAndLabor: [
        {key: "pl0", date: "", description: "", total: ""},
        {key: "pl1", date: "", description: "", total: ""},
        {key: "pl2", date: "", description: "", total: ""},
        {key: "pl3", date: "", description: "", total: ""},
        {key: "pl4", date: "", description: "", total: ""},
        {key: "pl5", date: "", description: "", total: ""},
        {key: "pl6", date: "", description: "", total: ""},
        {key: "pl7", date: "", description: "", total: ""},
        {key: "pl8", date: "", description: "", total: ""},
        {key: "pl9", date: "", description: "", total: ""},
    ],
    grandTotal: ""
};
  
function reducer(state, action) {
    switch (action.type) {
        case 'updateWR':
            return { ...state, wr: action.payload };
        case 'updateSelectOption':
            return { ...state, selectOption: action.payload };
        case 'updateRequestBy':
            return { ...state, requestBy: action.payload };
        case 'updateRequestDate':
            return { ...state, requestDate: action.payload };
        case 'updateOther':
            return { ...state, other: action.payload };
        case 'updateWorkRequest':
            return { ...state, workRequest: action.payload };
        case 'updateInlineRadioOptions':
            return { ...state, emergency: action.payload };
        case 'updatePLValues':
            return { ...state, partsAndLabor: action.payload };
        case 'updateGrandTotal':
            return { ...state, grandTotal: action.payload };
        case 'reset':
            return initialState;
        default:
        return state;
    }
}

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
// const dec2hex = (dec) => {
//     return dec.toString(16).padStart(2, "0")
// }

// // generateId :: Integer -> String
// const generateId = (len) => {
//     var arr = new Uint8Array((len || 40) / 2)
//     window.crypto.getRandomValues(arr)
//     return Array.from(arr, dec2hex).join('');
// }

// const tableRows = () => {
//     let rowArray = [];
//     let key = "";
//     for(let i = 0; i < 10; i++){
//         key = generateId(8);
//         rowArray.push({
//             key,
//             date: "",
//             description: "",
//             total: ""
//         })
//     }
//     return rowArray;
// };

const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_PUBLIC_KEY } = process.env;

const Form = () => {
    const [show, setShow] = useState(false);
    const [noAuth, setNoAuth] = useState(false);
    const [incomplete, setIncomplete] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [code, setCode] = useState("");
    const [state, dispatch] = useReducer(reducer, initialState);
    const htmlRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch({ type: `update${name}`, payload: value })
    };

    const handlePLChange = (evt, idx) => {
        const { name, value } = evt.target;
        const copyPLValues = [...state.partsAndLabor];
        copyPLValues[idx] = {
            ...copyPLValues[idx],
            [name]: value,
        };
        dispatch({ type: `updatePLValues`, payload: copyPLValues });
    };

    const formatMoney = (e) => {
        e.preventDefault();
        setIncomplete(false);
        const copyPLValues = [...state.partsAndLabor];
        const newTotal = copyPLValues
            .reduce((acc, cur) => (acc + Number(cur.total)), 0);
        dispatch({ 
            type: `updateGrandTotal`, 
            payload: newTotal.toLocaleString("en-US", {style: "currency", currency: "USD"}) 
        });
    };

    const validateForm = () => {
        return (state.wr &&
            (state.selectOption || state.other) &&
            state.requestBy &&
            state.requestDate &&
            state.emergency &&
            state.workRequest &&
            state.grandTotal);
    };

    const takeScreenshot = async () => {
        try {
            const form = htmlRef.current;
            const canvas = await html2canvas(form);
            return canvas.toDataURL();
        } catch(e) {
            console.error('Failed to take screenshot', e);
            return null;
        }
    };

    const sendEmail = (imgDataUrl) => {
        emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, {
            to_email: 'jmg.svcs@gmail.com',
            screenshot: imgDataUrl,
        }, REACT_APP_PUBLIC_KEY)
            .then((result) => {
                flashSuccess();
            }).catch((error) => {
                console.error('Failed to send email:', error);
                setSendError(true);
            });
        return;
    };

    const reset = () => {
        setShow(false);
        setNoAuth(false);
        setSendError(false);
        setIncomplete(false);
        setSuccess(false);
        setCode("");
        dispatch({ type: "reset" })
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setNoAuth(false);
        setSendError(false);

        if(!code || code !== "JMGworkrequest") {
            setNoAuth(true);
            return;
        } else {
            const imgDataUrl = await takeScreenshot();
            if(imgDataUrl){
                await sendEmail(imgDataUrl);            
            }
        }
    };

    const flashSuccess = () => {
        setShow(false);
        setSuccess(true);
        setTimeout(() => {
            reset();
        }, 3000);
    };

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            submitForm(e);
        }
    };

    return (
        <div className="container" >
            <div className="form-header">
                <div>
                    <h1>JMG Services, LLC</h1>
                </div>
                <div>
                    <h5>JR Fisher</h5>
                    <h5>PO Box 1856</h5>
                    <h5>Sandy, UT 84091</h5>
                    <h6>Cell Phone: (801) 518-6117</h6>
                </div>
            </div>

            <form id="simple-form" ref={htmlRef}>
                <div className="form-group">
                    <label htmlFor="WR">WR:</label>
                    <input 
                        className="form-control" 
                        id="WR" 
                        name="WR" 
                        value={state.wr} 
                        onChange={handleChange}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="SelectOption">Select a site:</label>
                    <select 
                        className="form-control" 
                        id="SelectOption" 
                        name="SelectOption" 
                        value={state.selectOption} 
                        onChange={handleChange}
                        required
                    >
                        <option value="">--Select a site--</option>
                        {Sites.map((site) => (
                            <option key={site} value={site}>{site}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="requestBy">Requested By:</label>
                    <input 
                        className="form-control" 
                        id="RequestBy" 
                        name="RequestBy"
                        value={state.requestBy}
                        onChange={handleChange}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="RequestDate">Date Requested:</label>
                    <input 
                        className="form-control" 
                        id="RequestDate" 
                        name="RequestDate"
                        value={state.requestDate}
                        onChange={handleChange}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="other">Other:</label>
                    <input 
                        className="form-control" 
                        id="Other" 
                        name="Other" 
                        value={state.other}
                        onChange={handleChange}
                        required 
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="InlineRadioOptions">Emergency:</label><br />
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="InlineRadioOptions" 
                            id="yes" 
                            value="yes"
                            checked={state.emergency === "yes"} 
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="yes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="InlineRadioOptions" 
                            id="no" 
                            value="no"
                            checked={state.emergency === "no"}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="no">No</label>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="work_request">Work Request:</label>
                    <textarea 
                        className="form-control" 
                        id="WorkRequest" 
                        name="WorkRequest" 
                        value={state.workRequest}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>

                <table className="table">
                    <thead>
                        <tr className="row">
                            <th className="col">Date</th>
                            <th className="col-6">Parts & Labor</th>
                            <th className="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {state.partsAndLabor.map((row, i) => (
                            <tr key={row.key} className="row">
                                <td className="col">
                                    <div className="input-group">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id={`date_${i}`} 
                                            name="date"
                                            value={state.partsAndLabor[i]?.date || ""}
                                            onChange={(e) => handlePLChange(e, i)}
                                        />
                                    </div>
                                </td>
                                <td className="col-6">
                                    <div className="input-group">
                                        <textarea 
                                            className="form-control" 
                                            id={`description_${i}`} 
                                            name="description" 
                                            value={state.partsAndLabor[i]?.description || ""}
                                            onChange={(e) => handlePLChange(e, i)}
                                        ></textarea>
                                    </div>
                                </td>
                                <td className="col">
                                    <div className="input-group">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id={`total_${i}`} 
                                            name="total" 
                                            placeholder="0.00"
                                            value={state.partsAndLabor[i]?.total || ""}
                                            onChange={(e) => handlePLChange(e, i)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="form-group">
                    <Button variant="primary" onClick={(e) => formatMoney(e)}>Calculate</Button>
                </div>

                <div className="form-group">
                    <label htmlFor="GrandTotal">Total:</label>
                    <input 
                        className="form-control" 
                        id="GrandTotal" 
                        name="GrandTotal" 
                        value={state.grandTotal}
                        onChange={() => {}}
                        required 
                    />
                </div>

                {success && (
                    <Alert variant="success">
                        Your work request was sent successfully.
                    </Alert>
                )}

                {incomplete && (
                    <Alert variant="danger">
                        Please make sure all fields are complete and you have clicked calculate to add up the total.
                    </Alert>
                )}

                <Button 
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        setNoAuth(false);
                        if(validateForm()){
                            setIncomplete(false);
                            setShow(true);
                        } else {
                            setIncomplete(true);
                        }
                    }}
                >Submit</Button>
            </form>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Authorization</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input 
                        className="form-control" 
                        id="Auth" 
                        name="Auth"
                        type="password"
                        value={code}
                        placeholder="Authorization Code"
                        onChange={(e) => setCode(e.target.value)}
                        onKeyDown={handleKeyPress}
                        required 
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        onClick={submitForm}
                    >
                        Save & Submit
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        setNoAuth(false);
                        setSendError(false);
                        setCode("");
                        setShow(false);
                    }}>
                        Close
                    </Button>
                </Modal.Footer>

                {noAuth && (
                    <Alert variant="danger">
                        The authorization code is invalid. Please check the code and try again.
                    </Alert>
                )}

                {sendError && (
                    <Alert variant="danger">
                        Something went wrong with your send request.  Please try again.
                    </Alert>
                )}
            </Modal>
        </div>
    );
}

export default Form;